import * as React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'

// markup
const Politique = () => {
  return (
    <Wolflayout>
      <FestifHelmet title='Politique de confidentialité | Le Festif! de Baie-Saint-Paul' />
      <div className="container content pb-12">
        <h1 className="pb-8">Politique de confidentialité</h1>
        <p>Dans le but d’améliorer ton expérience sur notre site, de suivre le trafic sur notre site web pour améliorer les pépins et de mieux cibler tes intérêts dans nos campagnes publicitaires, Le Festif! de Baie-Saint-Paul utilise des pixels et des cookies (témoins).</p>
        <br/>
        <p>Si tu désires modifier l’autorisation que tu nous as donnée pour l’utilisation des cookies, il est possible de le faire en configurant les paramètres de ton fureteur.</p>
        <br/>
        <p>Nous utilisons tes données pour communiquer avec toi et te livrer du contenu demandé, comme l’infolettre. Pour ne plus la recevoir, il est possible de se désabonner en cliquant sur le lien dans l’envoi. </p>
        <p>Le Festif! de Baie-Saint-Paul s’engage à protéger tes données personnelles et ta vie privée. La collecte d’informations personnelles est requise lors de l’achat de billets, d’abonnement à notre infolettre ou d’inscription à un concours. Les renseignements fournis sont strictement utilisés pour communiquer avec toi avec ton consentement et te proposer un contenu plus personnalisé. Notre partenaire de billetterie, Lepointdevente.com, a lui aussi une politique de confidentialité que tu peux <a target="_blank" rel="noopener noreferrer" href="https://lepointdevente.com/conditions/">consulter</a>. </p>
        <br/>
        <p>Nous nous conformons à la législation en vigueur en ce qui concerne le traitement des données personnelles, la confidentialité et le respect de la vie privée. En cas d’incident, nous avons un plan de gestion et nous tâcherons de le communiquer à la Commission d’accès à l’information. Ce n’est qu’en cas de contrainte légale que Le Festif! de Baie-Saint-Paul transmettra tes données personnelles à un tiers. </p>
        <br/>
        <p>La personne responsable de la protection des renseignements personnels est Stéphanie Gingras. N’hésite pas à communiquer avec elle au info@lefestif.ca pour tout questionnement.</p>
        <br/>
        <br/>
        <p><b>Data-Coop Culture et Pixel Culture.</b></p>
        <br/>
        <p>Nous utilisons le service Data-Coop Culture pour collecter des informations de navigation des visiteurs de notre site. Grâce au Pixel Culture installé sur ce dernier, nous recueillons de l’information afin de mieux comprendre les intérêts et les goûts culturels des internautes qui consultent nos pages ou effectuent des achats. Ces données collectées pourront servir à te suggérer des activités culturelles correspondant à tes intérêts par le biais de publicités personnalisées diffusées sur des réseaux publicitaires d’autres sites que vous visiterez.</p>
        <br/>
        <p>Si tu ne souhaites pas recevoir de telles publicités personnalisées, tu peux te désabonner: </p>
        <ul className="!font-light !pl-[120px] !list-outside">
          <li>en modifiant tes paramètres sur les services de médias sociaux que tu utilises, </li>
          <li>en configurant les services publicitaires des sites sur lesquels tu navigues (par exemple via le gestionnaire de préférences publicitaires de Google). </li>
        </ul>
        <br/>
        <p>Tu peux également choisir de ne pas recevoir d’annonces personnalisées de la part de réseaux publicitaires tiers qui sont membres de l’Alliance de la publicité numérique du Canada. Visite la <a target="_blank" rel="noopener noreferrer" href="https://youradchoices.ca/fr/outils">page de désabonnement de la DAAC</a> pour plus d’informations.</p>

      </div>
    </Wolflayout>
  )
}

export default Politique
